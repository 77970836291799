import axios from 'axios'

export default {
  namespaced: true,
  state: {
    cashes: [],
    entities: [],
    totalCashes: 0,
  },
  mutations: {
    SET_CASHES(state, cashes) {
      state.cashes = cashes
    },
    SET_ENTITIES(state, entities) {
      state.entities = entities
    },
    SET_TOTAL_CASHES(state, total) {
      state.totalCashes = total
    },
  },
  actions: {
    async getBox ({ commit }, params) {
      try {
        const { data } = await axios.get('/api/ticket/box', {params});
        commit('SET_CASHES', data.data.data);
        commit('SET_ENTITIES', data.entities);
        commit('SET_TOTAL_CASHES', data.data.total);
      } catch (e) {
        commit('SET_LOGGED', false);
        commit('SET_TOKEN', null)
      }
    },
  },
  getters: {
    cashState(state) {
      return state.cashes.some(item => item.state === 1)
    }
  }
}