import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import box from './box'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    box
  },
  plugins: [createPersistedState()],
})
