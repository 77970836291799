import axios from 'axios'

export default {
  namespaced: true,
  state: {
    logged: false,
    user: null,
    token: '',
    errores: null
  },
  mutations: {
    SET_LOGGED(state, logged) {
      state.logged = logged
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_ERROR(state, error) {
      state.errores = error
    },
    SET_TOKEN(state, token) {
      state.token = token
      window.localStorage.setItem("token", token)
    },
  },
  actions: {
    async login ({ dispatch, commit }, credentials) {
      try {
        const { data } = await axios.post('/api/login', credentials).catch((error) => {
          commit('SET_ERROR', error.response.data.errors);
        });
        commit('SET_TOKEN', data);
        commit('SET_ERROR', null);
        await dispatch('me')
      } catch (e) {
        commit('SET_LOGGED', false);
        commit('SET_TOKEN', null);
      }
    },
    async logout ({ commit }) {
      await axios.post('/api/logout');
      commit('SET_TOKEN', null)
      commit('SET_USER', null)
      commit('SET_LOGGED', false);
    },
    async me ({ commit }) {
      try {
        const { data } = await axios.get('/api/user');
        commit('SET_LOGGED', true)
        commit('SET_USER', data.assignment)
      } catch (e) {
        commit('SET_LOGGED', false)
        commit('SET_USER', null)
      }
    },
  },
  getters: {
    getState: state => {
      return state.user ? state.user[0].person_id : null
    }
  }
}