import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login',
    meta: { middleware:"guest" },
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware:"guest" },
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: () => import('../views/dashboard/Dashboard'),
    children: [
      {
        path: '/entidad',
        name: 'Entity',
        meta: { requiresAuth: true },
        component: () => import('../views/entities/Entity.vue')
      },
      {
        path: '/inicio',
        name: 'PageInit',
        meta: { requiresAuth: true },
        component: () => import('../views/dashboard/PageInit.vue')
      },
      {
        path: '/area',
        name: 'Area',
        meta: { requiresAuth: true },
        component: () => import('../views/areas/Area.vue')
      },
      {
        path: '/personal',
        name: 'Assignment',
        meta: { requiresAuth: true },
        component: () => import('../views/assignments/Assignment')
      },
      {
        path: '/producto',
        name: 'Product',
        meta: { requiresAuth: true },
        component: () => import('../views/products/Product')
      },
      {
        path: '/facturas',
        name: 'PurchaseInvoice',
        meta: { requiresAuth: true },
        component: () => import('../views/purchases/PurchaseInvoice')
      },
      {
        path: '/compras',
        name: 'Purchase',
        meta: { requiresAuth: true },
        component: () => import('../views/purchases/Purchase')
      },
      {
        path: '/ventas',
        name: 'Sales',
        meta: { requiresAuth: true },
        component: () => import('../views/sales/Sales')
      },
      {
        path: '/caja',
        name: 'Box',
        meta: { requiresAuth: true },
        component: () => import('../views/sales/Box')
      },
      {
        path: '/sell',
        name: 'VoucherSale',
        meta: { requiresAuth: true },
        component: () => import('../views/sales/VoucherSale')
      },
      {
        path: '/kardex',
        name: 'Kardex',
        meta: { requiresAuth: true },
        component: () => import('../views/products/Stock')
      },
      {
        path: '/AccionTerapeutic',
        name: 'therapeuticAct',
        meta: { requiresAuth: true },
        component: () => import('../views/detailProducts/therapeuticAct')
      },
      {

        path: '/reportesInventario',
        name: 'Inventory',
        meta: { requiresAuth: true },
        component: () => import('../views/reports/Inventory')
      },
      {

        path: '/reportesProducto',
        name: 'ProductReport',
        meta: { requiresAuth: true },
        component: () => import('../views/reports/ProductReport')
      },
      {

        path: '/reportesVentas',
        name: 'SalesReport',
        meta: { requiresAuth: true },
        component: () => import('../views/reports/SalesReport')
      },
      {
        path: '/mermas',
        name: 'Wastages',
        meta: { requiresAuth: true },
        component: () => import('../views/wastages/Wastage')
      },
      {
        path: '/realizar/mermas',
        name: 'ExecuteWastage',
        meta: { requiresAuth: true },
        component: () => import('../views/wastages/ExecuteWastage')
      },
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {

  if(!store.state['auth'].logged && !to.path.includes('/login') ){
    next({ path: '/login'})
    return
  }

  if(store.state['auth'].logged && to.path.includes('/login')){
    next({ path: '/inicio'})
    return
  }

  next()
})

export default router
